import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { ButtonGroup, Spinner, Form, InputGroup, Toast, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Button, Card, CardBody, CardText, CardTitle, Row, Col } from 'reactstrap';
import { TriangleHalf, Arrow90degLeft, Arrow90degRight, Save2Fill, Backspace } from 'react-bootstrap-icons';
import Tables from './gridtables';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import Axios from 'axios';
import dateformat from 'dateformat';
import common from '../../commonData';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import NumberToLetter from '../../converter';
import AddSortie from './addSortie';
import FilterModal from './filterModal';

var dta = [];
function ListeSortie() {
    const history = useHistory();
    const [datal, setDatal] = useState([]);
    const [datas, setDatas] = useState([]);
    const [client, setClient] = useState([]);
    const [load, setLoad] = useState(false);
    const [msg, setMsg] = useState('');
    const [isSave, setIsSave] = useState(false);
    const [clientNom, setClientNom] = useState('');
    const [idsort, setId] = useState(0);
    const [msgGravity, setMsgravity] = useState('info');
    const [show, setShow] = useState(false);
    const [showretour, setShowRetour] = useState(false);
    const [idlignretour, setIdLignRetour] = useState(0);
    const [annulretour, setAnnulRetour] = useState(0);
    const [qte, setQte] = useState(0);
    const [typeModal, setTypeModal] = useState(0);
    const [showAdd, setShowAdd] = useState(false);
    const [actualise, setActualise] = useState(false);
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [paramShow, setParamShow] = useState(false);
    var ladate = new Date();
    const [state, setState] = useState({
        demandeur: '',
        engin: '',
        nobon: '',
        datesort: dateformat(ladate.getMonth() + 1 + '/' + ladate.getDate() + '/' + ladate.getFullYear(), 'yyyy-mm-dd'),
        client_id: null,
        lignesort: []
    });

    useEffect(() => {
        const log = common.search(common.userdroit, 'SAD');
        const log1 = common.search(common.userdroit, 'SOR');

        setLoad(true);
        if (localStorage.getItem('user-info')) {
            if (log === undefined) {
                if (log1 === undefined) {
                    setMsg("Vous n'avez pas le droit d'effectuer cette action, merci de contacter l'administrateur");
                    setMsgravity('danger');
                    setIsSave(true);
                    setLoad(false);
                    return;
                }
            }
            (async function anyNameFunction() {
                await getSortie();
            })();
        } else {
            history.push('/login');
        }
    }, [actualise, history]);

    async function getSortie() {
        setLoad(true);
        await Axios.get(common.ipapi + '/api/client/clientunique')
            .then((response) => {
                setClient(response.data);
            })
            .catch((errors) => {});
        await Axios.get(common.ipapi + '/api/sortie/index')
            .then((response) => {
                dta = response.data.datas;
                setDatas(response.data.datas);
                setLoad(false);
            })
            .catch((errors) => {
                setLoad(false);
            });
    }

    function setShowFilterModals(param) {
        setShowFilterModal(true);
        setParamShow(param);
    }
    function onDelete(id) {
        const log = common.search(common.userdroit, 'SAD');
        const log1 = common.search(common.userdroit, 'SSOR');
        if (log === undefined) {
            if (log1 === undefined) {
                setMsg("Vous n'avez pas le droit d'effectuer cette action, merci de contacter l'administrateur");
                setMsgravity('danger');
                setIsSave(true);
                return;
            }
        }
        if (window.confirm("Voulez-vous supprimer cette sortie d'article ?")) {
            setLoad(true);
            Axios.delete(common.ipapi + '/api/sortie/destroy/' + id).then((response) => {
                if (response.data.status) {
                    const del = dta.filter((d) => d.id !== id);
                    dta = del;
                    setDatas(del);
                    setMsg(response.data.msg);
                    setMsgravity('info');
                    setIsSave(true);
                    setLoad(false);
                } else {
                    setMsg(response.data.msg);
                    setIsSave(true);
                    setLoad(false);
                    setMsgravity('danger');
                }
            });
        }
    }
    const retours = (d, bool) => {
        setShowRetour(true);
        setIdLignRetour(d.id);
        setAnnulRetour(bool);
        switch (bool) {
            case 1:
                setQte(d.quantite);
                break;

            default:
                setQte(d.quantiteretour);
                break;
        }
    };
    function retour() {
        if (window.confirm('Voulez-vous retourner cet article ?')) {
            setLoad(true);
            var url;
            if (annulretour === 0) {
                url = common.ipapi + '/api/sortie/retouroff/' + idlignretour;
            } else {
                url = common.ipapi + '/api/sortie/retouron/' + idlignretour;
            }
            var formData = new FormData();
            formData.append('quantite', qte);
            Axios({
                url: url,
                method: 'POST',
                headers: {
                    'Content-Type': 'form-data'
                },
                data: formData
            }).then((response) => {
                if (response.data.status === 1) {
                    setMsg(response.data.msg);
                    setMsgravity('info');
                    setIsSave(true);
                    setShowRetour(false);
                    setLoad(false);
                } else {
                    setMsg(response.data.msg);
                    setIsSave(true);
                    setLoad(false);
                }
            });
        }
    }

    function viewSortie(ids, typeM) {
        setLoad(true);
        Axios.get(common.ipapi + '/api/sortie/edit/' + ids).then((response) => {
            setClientNom(response.data.datas.client ? response.data.datas.client.raisonsocial : 'Néant');
            setDatal(response.data.datas.lignesort);
            setState({
                demandeur: response.data.datas.demand_par,
                engin: response.data.datas.engin,
                nobon: response.data.datas.numbon,
                datesort: response.data.datas.datesort ? dateformat(response.data.datas.datesort, 'yyyy-mm-dd') : '',
                client_id: response.data.datas.clients_id
            });
            setId(ids);
            setShow(true);
            setTypeModal(typeM);
            setLoad(false);
        });
    }

    function setAdds(val) {
        const log = common.search(common.userdroit, 'SAD');
        const log1 = common.search(common.userdroit, 'ADS');
        if (log === undefined) {
            if (log1 === undefined) {
                setMsg("Vous n'avez pas le droit d'effectuer cette action, merci de contacter l'administrateur");
                setMsgravity('danger');
                setIsSave(true);
                return;
            }
        }
        setState({
            demandeur: '',
            engin: '',
            nobon: '',
            datesort: dateformat(ladate.getMonth() + 1 + '/' + ladate.getDate() + '/' + ladate.getFullYear(), 'yyyy-mm-dd'),
            client_id: null,
            lignesort: []
        });
        setShowAdd(true);
        setId(0);
    }

    function setIds(id) {
        const log = common.search(common.userdroit, 'SAD');
        const log1 = common.search(common.userdroit, 'UPO');
        if (log === undefined) {
            if (log1 === undefined) {
                setMsg("Vous n'avez pas le droit d'effectuer cette action, merci de contacter l'administrateur");
                setMsgravity('danger');
                setIsSave(true);
                return;
            }
        }
        findSortieById(id);
    }

    async function findSortieById(id) {
        setLoad(true);
        var lignesort = [];
        await Axios.get(common.ipapi + '/api/sortie/edit/' + id)
            .then((responses) => {
                if (responses.data.status) {
                    lignesort = groupBy(responses.data.datas.lignesort);
                    setState({
                        demandeur: responses.data.datas.demand_par,
                        engin: responses.data.datas.engin,
                        nobon: responses.data.datas.numbon,
                        datesort: responses.data.datas.datesort ? dateformat(responses.data.datas.datesort, 'yyyy-mm-dd') : '',
                        client_id: responses.data.datas.clients_id,
                        lignesort: lignesort
                    });
                    setId(id);
                    setShowAdd(true);
                }
                setLoad(false);
            })
            .catch((errors) => {
                setLoad(false);
            });

        setLoad(false);
    }

    function groupBy(tableauObjets) {
        let t = [];
        setLoad(true);
        return tableauObjets.reduce(function (res, value, index) {
            t.push({
                id: value.pivot.id,
                article_id: value.id,
                qrbarre: value.qrbarre,
                quantite: value.pivot.quantite,
                descriptionart: value.pivot.descriptionart,
                prix: value.pivot.prix
            });
            return t;
        }, {});
    }

    const handleClose = () => {
        setShow(false);
        setParamShow(1);
        setShowFilterModal(false);
    };

    //------- IMPRIMER FACTURE -----------------
    function printBl(id) {
        let text =
            'Voulez-vous imprimer avec ou sans le prix ? \n Ok si vous voulez afficher le prix \n Annuler si vous ne voulez pas afficher le prix';
        let res = window.confirm(text);
        setLoad(true);
        var doc = new jsPDF();
        var width = doc.internal.pageSize.getWidth();
        const getEdit = Axios.get(common.ipapi + '/api/sortie/edit/' + id);
        Axios.all([getEdit])
            .then(
                Axios.spread((...responses) => {
                    const dataFactures = [];
                    var i = 0;
                    let mont = 0,
                        t = 0;
                    let remise;
                    //console.log(responses[0].data.datas.lignesort);
                    responses[0].data.datas.lignesort.forEach((row) => {
                        i++;
                        t = Math.round(row.pivot.prix * (row.pivot.quantite - row.pivot.quantiteretour));
                        mont += Math.round(row.pivot.prix * (row.pivot.quantite - row.pivot.quantiteretour));
                        res
                            ? dataFactures.push([
                                  { content: i },
                                  { content: row.pivot.descriptionart || row.descriptionart },
                                  { content: row.pivot.quantite - row.pivot.quantiteretour },
                                  { content: new Intl.NumberFormat('de-DE').format(t) }
                              ])
                            : dataFactures.push([
                                  { content: i },
                                  { content: row.pivot.descriptionart || row.descriptionart },
                                  { content: row.pivot.quantite }
                              ]);
                    });
                    if (res !== false) {
                        remise = responses[0].data.datas.client.remise
                            ? (responses[0].data.datas.client.remise.pourcentage / 100) * mont
                            : 0;
                        dataFactures.push([
                            { content: 'MONTANT HT', colSpan: 3, styles: { fontStyle: 'bold', fontSize: 10 } },
                            { content: new Intl.NumberFormat('de-DE').format(mont), styles: { fontStyle: 'bold', fontSize: 12 } }
                        ]);
                        if (responses[0].data.datas.client.remise) {
                            dataFactures.push([
                                { content: 'REMISE', colSpan: 3, styles: { fontStyle: 'bold', fontSize: 10 } },
                                { content: new Intl.NumberFormat('de-DE').format(remise), styles: { fontStyle: 'bold', fontSize: 12 } }
                            ]);
                        }
                        dataFactures.push([
                            { content: 'MONTANT TVA', colSpan: 3, styles: { fontStyle: 'bold', fontSize: 10 } },
                            {
                                content: responses[0].data.datas.client.remise
                                    ? new Intl.NumberFormat('de-DE').format(Math.round((mont - remise) * 0.18))
                                    : new Intl.NumberFormat('de-DE').format(Math.round(mont * 0.18)),
                                styles: { fontStyle: 'bold', fontSize: 12 }
                            }
                        ]);
                        dataFactures.push([
                            { content: 'TOTAL', colSpan: 3, styles: { fontStyle: 'bold', fontSize: 10 } },
                            {
                                content: responses[0].data.datas.client.remise
                                    ? new Intl.NumberFormat('de-DE').format(Math.round(mont - remise + (mont - remise) * 0.18))
                                    : new Intl.NumberFormat('de-DE').format(Math.round(mont + mont * 0.18)),
                                styles: { fontStyle: 'bold', fontSize: 12 }
                            }
                        ]);
                    }
                    common.ipapi === 'https://192.168.8.109:81' || common.ipapi === 'https://api.tracky-entrepot.com'
                        ? doc.addImage(common.logo, 'jpg', 14, 10, 30, 30)
                        : doc.addImage(common.logo1, 'jpg', 14, 10, 30, 30);
                    doc.setFontSize(16);
                    // doc.text(
                    //     common.ipapi === 'https://192.168.8.109:81' || common.ipapi === 'https://api.tracky-entrepot.com'
                    //         ? 'CLUB AUTO'
                    //         : 'HR MOTORS',
                    //     90,
                    //     17
                    // );
                    doc.setFontSize(12);
                    doc.setFillColor(225, 206, 154);
                    doc.rect(65, 19, width - 80, 10, 'F');
                    doc.setTextColor(0, 0, 0);
                    doc.text('BON DE LIVRAISON CLIENT', 90, 26);
                    doc.setFontSize(10);
                    doc.setDrawColor(0);
                    doc.rect(14, 40, width / 2 - 30, 21);
                    doc.rect(width / 2 - 10, 40, 100, 21);
                    doc.setTextColor(0, 0, 0);
                    doc.text(
                        responses[0].data.datas.client ? 'Non : ' + responses[0].data.datas.client.raisonsocial : 'Nom :',
                        width / 2 - 9,
                        45
                    );
                    doc.text(
                        responses[0].data.datas.client ? 'Télephone : ' + responses[0].data.datas.client.telcli : 'Télephone :',
                        width / 2 - 9,
                        50
                    );
                    responses[0].data.datas.client
                        ? doc.text('Type client : ' + responses[0].data.datas.client.sisociete, width / 2 - 9, 55)
                        : doc.text('Type client: ', width / 2 - 9, 55);

                    doc.text('N° BL : ' + responses[0].data.datas.numbon, 15, 45);
                    doc.text('Date livraison : ' + dateformat(responses[0].data.datas.datesort, 'dd/mm/yyyy'), 15, 50);
                    doc.text('Engin : ' + responses[0].data.datas.engin, 15, 55);

                    doc.autoTable({
                        styles: {
                            fillColor: [0, 0, 0],
                            textColor: [255, 255, 255],
                            cellPadding: 1.5,
                            halign: 'center'
                        },
                        columnStyles: {
                            0: {
                                halign: 'center',
                                fillColor: [255, 255, 255],
                                textColor: [0, 0, 0],
                                cellPadding: 0.75,
                                fontSize: 10
                            },
                            1: {
                                halign: 'left',
                                fillColor: [255, 255, 255],
                                textColor: [0, 0, 0],
                                cellPadding: 0.75,
                                fontSize: 10
                            },
                            2: {
                                halign: 'center',
                                fillColor: [255, 255, 255],
                                textColor: [0, 0, 0],
                                cellPadding: 0.75,
                                fontSize: 10
                            },
                            3: {
                                halign: 'center',
                                fillColor: [255, 255, 255],
                                textColor: [0, 0, 0],
                                cellPadding: 0.75,
                                fontSize: 10
                            },
                            4: {
                                halign: 'center',
                                fillColor: [255, 255, 255],
                                textColor: [0, 0, 0],
                                cellPadding: 0.75,
                                fontSize: 10
                            }
                        },
                        theme: 'grid',
                        startY: 75,
                        margin: { bottom: 35 },
                        horizontalPageBreak: true,
                        head: res ? [['Ordre', 'Article', 'Quantité', 'Montant']] : [['Ordre', 'Article', 'Quantité']],
                        body: dataFactures
                    });
                    var finalY = doc.lastAutoTable.finalY;
                    if (finalY > 217) {
                        doc.addPage();
                    }
                    const pageCount = doc.internal.getNumberOfPages();
                    for (var u = 1; u <= pageCount; u++) {
                        doc.setPage(u);
                        doc.setFontSize(10);
                        doc.text('Page ' + String(u) + ' sur ' + String(pageCount), 210 - 18, 292, null, null, 'right');
                    }
                    if (res !== false) {
                        doc.text('Arrêté à la somme de:', 15, 228);
                        doc.setFontSize(8);
                        doc.text(NumberToLetter(Math.ceil(mont + mont * 0.18)).toUpperCase() + ' FCFA', 15, 234); //235tSize(10)
                        doc.setDrawColor(0);
                        doc.rect(14, 230, width - 28, 6);
                    }
                    doc.setFontSize(10);

                    doc.text('Visa direction:', 15, 245);
                    doc.setDrawColor(0);
                    // doc.rect(14, 246, 60, 22);
                    doc.text('Visa client:', 135, 245);
                    doc.setDrawColor(0);
                    // doc.rect(135, 246, 60, 22);
                    doc.setFontSize(8);

                    doc.setLineWidth(1.5);
                    doc.setFontSize(8);
                    doc.setLineWidth(1.5);
                    doc.setDrawColor(0, 0, 0);
                    doc.line(0, 275, width, 275);
                    doc.text(
                        'Siège social - Marcory Boulevard de Marseille - Zone 4 - 26 BP 695 Abidjan 26 - Tel.: 27 21 36 39 79 / Fax: 27 21 34 59 59',
                        105,
                        280,
                        null,
                        'center'
                    );
                    doc.text(
                        'RCCM N°CI-ABJ-2012-A-5995 - Compte SIB N° CI 007 0106885056950010020 / Cel: 07 08 29 40 40 /  Email: vicaso11@hotmail.fr ',
                        105,
                        285,
                        null,
                        'center'
                    );
                    doc.setLineWidth(0.5);
                    setLoad(false);
                    doc.setFontSize(8);
                    doc.setTextColor(254, 0, 0);
                    doc.text('* Aucun produit ne peut être retourner 15 jours après livraison', 14, doc.lastAutoTable.finalY + 3);
                    //doc.autoPrint({variant: 'non-conform'});
                    doc.save('bl.pdf');
                })
            )
            .catch((errors) => {
                setLoad(false);
            });
    }
    return (
        <>
            <Toast id="toast" onClose={() => setIsSave(false)} show={isSave} delay={3000} className={'bg-' + msgGravity} autohide>
                <Toast.Header>
                    <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                    <strong className="me-auto">Info</strong>
                </Toast.Header>
                <Toast.Body className="dark">{msg}</Toast.Body>
            </Toast>
            <Modal show={load} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    Veuillez patienter.......
                    <Spinner animation="border" variant="success" />
                </Modal.Body>
            </Modal>
            {showAdd ? (
                <AddSortie
                    id={idsort}
                    setShowAdd={setShowAdd}
                    show={showAdd}
                    setIsSave={setIsSave}
                    setDatas={setDatas}
                    setMsg={setMsg}
                    setMsgravity={setMsgravity}
                    actualise={actualise}
                    setActualise={setActualise}
                    state={state}
                />
            ) : (
                <></>
            )}
            {showFilterModal ? (
                <FilterModal
                    setShowFilterModals={setShowFilterModals}
                    showFilterModal={showFilterModal}
                    setMsg={setMsg}
                    setIsSave={setIsSave}
                    setMsgravity={setMsgravity}
                    setLoad={setLoad}
                    load={load}
                    handleClose={handleClose}
                    setData={setDatas}
                    paramShow={paramShow}
                />
            ) : (
                <></>
            )}

            {common.search(common.userdroit, 'SAD') !== undefined || common.search(common.userdroit, 'SOR') !== undefined ? (
                <div className="content contents">
                    <Row>
                        <Col md="12">
                            <div id="tablecontent">
                                <Tables
                                    rows={datas}
                                    isLoading={load}
                                    onDelete={onDelete}
                                    setIds={setIds}
                                    setIsSave={setIsSave}
                                    setMsg={setMsg}
                                    viewSortie={viewSortie}
                                    clients={client}
                                    setAdds={setAdds}
                                    printBl={printBl}
                                    setShowFilterModals={setShowFilterModals}
                                    setMsgravity={setMsgravity}
                                    setDatas={setDatas}
                                    actualise={actualise}
                                    setActualise={setActualise}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            ) : (
                <Card style={{ width: '25rem' }} className="centerCard">
                    <CardBody>
                        <CardTitle tag="h5">
                            <TriangleHalf size={60} color="red"></TriangleHalf> Avertissement
                        </CardTitle>
                        <CardText>Vous n'êtes pas autorisé à utiliser cette section. Merci de contacter votre administrateur</CardText>
                    </CardBody>
                </Card>
            )}
            <Modal show={show} size="xl">
                <Form noValidate>
                    <Modal.Header>
                        {typeModal === 1 ? "INFO SORTIE D'ARTICLE" : 'FACTURER CETTE SORTIE'}{' '}
                        <Backspace onClick={handleClose} color="red" size={30} />
                    </Modal.Header>

                    <Modal.Body>
                        <div id="lightbody">
                            <Row>
                                <Col md={6}>
                                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                        <Form.Label column sm={3}>
                                            N° bon sortie:
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Form.Control
                                                type="text"
                                                placeholder="N° bon sortie"
                                                aria-describedby="inputGroupPrepend"
                                                disabled
                                                size="sm"
                                                value={state.nobon}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                        <Form.Label column sm={3}>
                                            Client:
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Form.Control
                                                type="text"
                                                aria-describedby="inputGroupPrepend"
                                                disabled
                                                size="sm"
                                                value={clientNom}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                        <Form.Label column sm={3}>
                                            Demandeur:
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Form.Control
                                                type="text"
                                                aria-describedby="inputGroupPrepend"
                                                disabled
                                                size="sm"
                                                value={state.demandeur}
                                            />
                                        </Col>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                        <Form.Label column sm={3}>
                                            Date sortie:
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Form.Control
                                                type="date"
                                                placeholder="Date sortie"
                                                aria-describedby="inputGroupPrepend"
                                                disabled
                                                size="sm"
                                                value={state.datesort}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                        <Form.Label column sm={3}>
                                            Engin:
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Form.Control
                                                type="text"
                                                placeholder="Engin"
                                                aria-describedby="inputGroupPrepend"
                                                disabled
                                                size="sm"
                                                value={state.engin}
                                            />
                                        </Col>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <table className="table table-striped-hover table-sm">
                                <thead>
                                    <tr>
                                        <th scope="col">C. barre</th>
                                        <th scope="col">Descr. art.</th>
                                        <th scope="col">Qté init</th>
                                        <th scope="col">Qté retour</th>
                                        <th scope="col">Qté reste</th>
                                        <th scope="col">Index</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {datal.map((d, index) => {
                                        var realqt = d.pivot.quantite - d.pivot.quantiteretour;
                                        return (
                                            <tr key={index} hover="true">
                                                <td>{d.qrbarre}</td>
                                                <td>{d.pivot.descriptionart || d.descriptionart}</td>
                                                <td>{d.pivot.quantite}</td>
                                                <td>{d.pivot.quantiteretour}</td>
                                                <td>{realqt}</td>
                                                <td>{d.pivot.index_transaction}</td>
                                                <td>
                                                    <ButtonGroup aria-label="Basic example">
                                                        {/* <Link to={`/admin/article/fiche/${d.id}`} title="Voir tous les détails de l'article"><Eye  size={20}  color='royalblue'/></Link> */}
                                                        {d.pivot.si_retour !== 1 ? (
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={<Tooltip id="tooltip-disabled">Retourner de l'article</Tooltip>}
                                                            >
                                                                <a href="#/admin/sorties" onClick={() => retours(d.pivot, 1)}>
                                                                    <Arrow90degLeft color="green" size={20} />
                                                                </a>
                                                            </OverlayTrigger>
                                                        ) : (
                                                            <a href="#/admin/sorties" onClick={() => retours(d.pivot, 0)}>
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip id="tooltip-disabled">
                                                                            Annuler le retourner de l'article
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <Arrow90degRight color="green" size={20} />
                                                                </OverlayTrigger>
                                                            </a>
                                                        )}
                                                    </ButtonGroup>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Backspace onClick={handleClose} color="red" size={30} />
                        {typeModal === 2 ? (
                            <Button type="submit" variant="ligth">
                                <Save2Fill color="#000" size={20} /> Enregister
                            </Button>
                        ) : (
                            <></>
                        )}
                    </Modal.Footer>
                </Form>
                <Modal show={showretour} onHide={() => setShowRetour(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Modal heading</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {annulretour === 1 ? (
                            <Form.Group as={Col} md="12" controlId={1}>
                                <InputGroup hasValidation>
                                    <InputGroup.Text id="inputGroupPrepend">Quantité à retourner:</InputGroup.Text>
                                    <Form.Control
                                        type="number"
                                        placeholder="Quantité à retourner"
                                        aria-describedby="inputGroupPrepend"
                                        required
                                        size="sm"
                                        min="1"
                                        value={qte}
                                        onChange={(e) => setQte(e.target.value)}
                                    />
                                    <Form.Control.Feedback type="invalid">Veuillez saisir la quantité article.</Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        ) : (
                            <Form.Group as={Col} md="12" controlId={1}>
                                <InputGroup hasValidation>
                                    <InputGroup.Text id="inputGroupPrepend">Quantité à retourner:</InputGroup.Text>
                                    <Form.Control
                                        type="number"
                                        placeholder="Quantité à retourner"
                                        aria-describedby="inputGroupPrepend"
                                        required
                                        size="sm"
                                        disabled
                                        max={qte}
                                        min="1"
                                        value={qte}
                                        onChange={(e) => setQte(e.target.value)}
                                    />
                                    <Form.Control.Feedback type="invalid">Veuillez saisir la quantité article.</Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => retour()}>
                            Enregister
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Modal>
        </>
    );
}

export default ListeSortie;
